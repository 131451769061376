var loader = document.querySelector('.loader')
var opacity = 0.5
var load = setInterval(()=>{
	loader.style.opacity = opacity;
	opacity-=0.1
	if(opacity <=0){
		clearInterval(load)
		loader.style.zIndex = "-55"
		document.querySelector('.header').style.opacity = "1"
	}
},50)
$('a[href^="#"').on('click', function(){
var href = $(this).attr('href');
$('html, body').animate({
    scrollTop: $(href).offset().top
},
    {
        duration: 700,
        easing: 'linear'
    })
return false
})
$('.to_up').on('click',function(){
$('html, body').animate({
    scrollTop: $('#top').offset().top
})
return false
})
$(document).scroll(function(){
if(window.scrollY != 0){
document.querySelector('.to_up').style.display = "inline"

}else{
document.querySelector('.to_up').style.display = "none"
document.querySelector('.header').style.position = "static"
}
})
$('.mobile_nav').on('click',()=>{
document.querySelector('.mobile_nav').style.opacity = "0"
document.querySelector('.mobile_nav').style.zIndex = "-5"
})
$('.mobile_button').click(()=>{
    console.log('1')
document.querySelector('.mobile_nav').style.opacity = "1"
document.querySelector('.mobile_nav').style.zIndex = "55"
})
//mk
$('.mk').click(()=>{
document.querySelector('.mobile_nav').style.opacity = "0"
document.querySelector('.mobile_nav').style.zIndex = "-5"
})
// AOS.init();
// SmoothScroll({
//     // Время скролла 400 = 0.4 секунды
//     animationTime    : 800,
//     // Размер шага в пикселях
//     stepSize         : 75,
//
//     // Дополнительные настройки:
//
//     // Ускорение
//     accelerationDelta : 10,
//     // Максимальное ускорение
//     accelerationMax   : 2,
//
//     // Поддержка клавиатуры
//     keyboardSupport   : true,
//     // Шаг скролла стрелками на клавиатуре в пикселях
//     arrowScroll       : 50,
//
//     // Pulse (less tweakable)
//     // ratio of "tail" to "acceleration"
//     pulseAlgorithm   : true,
//     pulseScale       : 4,
//     pulseNormalize   : 1,
//
//     // Поддержка тачпада
//     touchpadSupport   : true,
// })
